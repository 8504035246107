import { Route, Redirect } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { userFragments } from './fragments';

const CURRENT_USER = gql`
  query currentUser {
    currentUser @client {
      ...UserFields
    }
  }
  ${userFragments.user}
`;

export default function PrivateRoute({ location, ...rest }) {
  const { data: { currentUser } = {} } = useQuery(CURRENT_USER);
  const loggedIn = !!currentUser?.id;

  if (!loggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  return <Route {...rest} />;
}
