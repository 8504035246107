import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { ConfigProvider } from 'antd';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import Routes from './routes';
import client from './lib/apolloClient';
import Wrapper from './App.style';
import './App.css';

library.add(far, fab);

export default () => (
  <BrowserRouter>
    <ApolloProvider client={client}>
      <ConfigProvider locale={locale}>
        <Wrapper>
          <Routes />
        </Wrapper>
      </ConfigProvider>
    </ApolloProvider>
  </BrowserRouter>
);
