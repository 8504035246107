import styled from 'styled-components';

export default styled.div`
  .loading-screen {
    min-height: 100vh;
    display: flex;
    height: 100%;
    flex: 1 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .overflowContainer {
    height: calc(100vh - 74px);
    overflow: auto;
  }

  .ant-layout-sider {
    background-color: #f4e5dc;
  }

  .ant-menu {
    margin-top: 25px;
    background: #f4e5dc !important;
  }

  .menuItem {
    height: 50px !important;
    line-height: 50px !important;
    padding-left: 16px !important;
    margin-top: 0px !important;
  }

  .menuLink {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menuIconContainer {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
  }

  .ant-menu-item .menuIcon {
    width: 25px;
    height: 25px;
    color: #4a4c63 !important;
    font-weight: 600;
  }

  .ant-menu-item-selected {
    background-color: inherit !important;
  }

  .ant-menu-item.ant-menu-item-selected .menuIconContainer {
    background-color: #e3caba;
  }

  .ant-table-thead .ant-table-cell {
    white-space: nowrap;
  }

  .ant-pagination {
    padding-right: 15px;

    .ant-pagination-item {
      display: none !important;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      background: #f7f7f7;
      color: #4a4c63;
    }
  }
  .tableActions {
    display: flex;
    align-items: center;
  }
  .tablecIconButton {
    margin-left: 5px;
  }
  .tableLink {
    font-size: 14px;
    font-weight: 500;
    color: #7dc490;
    text-decoration: underline;
    margin-right: 10px;
    white-space: nowrap;
  }
  .toggleableChip {
    cursor: pointer;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #4a4c65;
    border-radius: 22px;
    font-size: 14px;
    color: #4a4c63;
    white-space: nowrap;
  }
  .toggleableChip + .toggleableChip {
    margin-left: 5px;
  }
  .toggleableChip.active {
    background-color: #4a4c65;
    color: #fff;
  }
`;
