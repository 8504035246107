import { gql } from '@apollo/client';

const userFragments = {
  user: gql`
    fragment UserFields on User {
      id
      firstName
      lastName
      email
      roleIds
      roles {
        id
        name
        displayName
        allowsAppointments
        allowsPatients
        allowsClinicHistory
      }
      specialty {
        key
        name
      }
      bio
      avatarPath
      thumbAvatarPath: avatarPath(size: { width: 100, height: 100 })
      abilities
      zoomId
      allowedUserIds
      allowsFaceToFaceAppointments
      appointmentAddress
    }
  `,
};

const patientFragments = {
  user: gql`
    fragment PatientFields on User {
      id
      firstName
      lastName
      email
      avatarPath
      thumbAvatarPath: avatarPath(size: { width: 100, height: 100 })
      createdAt
      subscriptionActive
      subscriptionStartsAt
      subscriptionValidUntil
      product {
        id
        frequency
        frequencyType
        amount
        currency
      }
      subscriptionAuthorizer {
        key
        name
      }
      userProfile {
        id
        bornAt
        enabledAt
        sex {
          key
          name
        }
        genderId
        countryId
        stateId
        cityId
        alternateContactName
        alternateContactPhone
        contactPhone
        motivationToStart
        motivationList {
          key
          name
        }
        timeSinceLastExercise {
          key
          name
        }
        timeSinceLastTreatment {
          key
          name
        }
        timeSinceWeightGain {
          key
          name
        }
        pain {
          key
          name
        }
        painDescription
        exerciseDuringAdolescence {
          key
          name
        }
        familyExercise {
          key
          name
        }
        jointSurgery {
          key
          name
        }
        jointSurgeryDescription
        diseaseList {
          key
          name
        }
        programId
        program {
          id
          name
        }
        currentWeek
        healthCarePlanId
        healthCareAffiliateNumber
        fruitsAllowed
        recommendedWater
        goalWeight
        currentHeight
        initialWeight
        currentWeight
        foodPreferenceId
        foodPreference {
          id
          name
        }
        healthCarePlanCode
        unreadUserMessagesCount
        contactChannelList {
          key
          name
        }
        professionalsAssigned
      }
    }
  `,
};

const foodItemFragments = {
  foodItem: gql`
    fragment FoodItemFields on FoodItem {
      id
      name
      imagePath
      thumbImagePath: imagePath(size: { width: 100, height: 100 })
      programIds
      carbsTotalGrams
      isFruit
      foodItemFoodPreferences {
        id
        foodPreferenceId
        allowed
      }
      foodPreferences {
        id
        name
      }
      programs {
        id
        name
      }
    }
  `,
};

const menuItemFragments = {
  menuItem: gql`
    fragment MenuItemFields on MenuItem {
      id
      title
      content
      programId
      foodPreferenceId
    }
  `,
};

const workshopFragments = {
  workshop: gql`
    fragment WorkshopFields on Workshop {
      id
      workshopType
      workshopCategoryId
      workshopCategory {
        id
        name
      }
      title
      description
      dictator
      durationInMinutes
      startsAt
      endsAt
      programId
      program {
        id
        name
      }
      youtubeUrl
      youtubeId
      coverPath
      thumbCoverPath: coverPath(size: { width: 100, height: 100 })
      mediumCoverPath: coverPath(size: { width: 500, height: 500 })
      isPublished
      workshopTimes {
        id
        mode
        startsAt
        maxCapacity
        weekdays
        address
        meetingUrl
      }
      isFree
      level {
        key
        name
      }
      classroomId
      statusLabel
      weekFrom
      weekTo
      video {
        id
        blobId
        name
        url
        createdAt
      }
    }
  `,
};

const articleFragments = {
  article: gql`
    fragment ArticleFields on Article {
      id
      title
      body
      coverPath
      isPublished
      isFree
      articleType
      programIds
      foodPreferenceIds
      workshopCategoryIds
      week
    }
  `,
};

const classroomFragments = {
  classroom: gql`
    fragment ClassroomFields on Classroom {
      id
      name
      description
      coverPath
      thumbCoverPath: coverPath(size: { width: 300, height: 300 })
      dictator
    }
  `,
};

const learnItemFragments = {
  learnItem: gql`
    fragment LearnItemFields on LearnItem {
      id
      title
      body
      programIds
      foodPreferenceIds
      workshopCategoryIds
      isPublished
      isFree
      durationInMinutes
      type
      youtubeUrl
      coverPath
      tagList
      images {
        id
        blobId
        name
        url
        isImage
        createdAt
      }
      week
      dayOfWeek
      durationInDays
      isMandatory
      genderIds
      author
      video {
        id
        blobId
        name
        url
        createdAt
      }
      audio {
        id
        blobId
        name
        url
        createdAt
      }
    }
  `,
};

const channelFragments = {
  channel: gql`
    fragment ChannelFields on Channel {
      id
      name
      startsAt
      currentWeek
      maxCapacity
      patientsCount
      remainingDaysToStart
      companionId

      companion {
        id
        firstName
        lastName
      }
    }
  `,
};

const cellFragments = {
  cell: gql`
    fragment CellFields on Cell {
      id
      name
      countryId
      country {
        id
        name
      }
      patientsCount
    }
  `,
};

export {
  userFragments,
  patientFragments,
  foodItemFragments,
  menuItemFragments,
  workshopFragments,
  articleFragments,
  classroomFragments,
  learnItemFragments,
  channelFragments,
  cellFragments,
};
