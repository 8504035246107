/* eslint-disable no-use-before-define */
/* eslint-disable no-console */

import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import { persistCacheSync, LocalStorageWrapper } from 'apollo3-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import { relayStylePagination } from '@apollo/client/utilities';

const AUTH_HEADERS = 'inpass-auth';

const middlewareLink = new ApolloLink((operation, forward) => {
  const { operationName } = operation;

  let authHeaders = {};

  if (operationName !== 'userLogin') {
    authHeaders = JSON.parse(localStorage.getItem(AUTH_HEADERS));
  }

  operation.setContext(({ headers }) => ({
    headers: {
      ...authHeaders,
      ...headers,
    },
  }));

  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const accessToken = headers.get('access-token');

      if (accessToken) {
        const authHeaders = {
          'access-token': accessToken,
          client: headers.get('client'),
          uid: headers.get('uid'),
        };

        localStorage.setItem(AUTH_HEADERS, JSON.stringify(authHeaders));
      }
    }

    return response;
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

      if (extensions?.code === 'AUTHENTICATION_ERROR') {
        localStorage.clear();
        client.clearStore().then(() => window.location.replace('/login'));
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        patients: relayStylePagination(),
        learnItems: relayStylePagination(),
        users: relayStylePagination(),
        workshops: relayStylePagination(),
        foodItems: relayStylePagination(),
        articles: relayStylePagination(),
        appointmentEdges: relayStylePagination(),
      },
    },
    CurrentUser: {
      fields: {
        notifications: relayStylePagination(),
      },
    },
    UserProfile: {
      fields: {
        userNotes: relayStylePagination(),
        userMessages: relayStylePagination(),
      },
    },
    Workshop: {
      fields: {
        userWorkshops: relayStylePagination(),
      },
    },
  },
});

persistCacheSync({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, apolloLogger, middlewareLink, afterwareLink, httpLink]),
  cache,
});

export default client;
