import React, { useEffect, Suspense, lazy } from 'react';
import { useLocation, Route, Switch } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import Loading from './components/shared/loading';

const AccountConfirmedComponent = lazy(() => import('./pages/accountConfirmed'));
const ForgotComponent = lazy(() => import('./pages/forgot'));
const RecoverComponent = lazy(() => import('./pages/recover'));
const LoginComponent = lazy(() => import('./pages/login'));
const RegisterComponent = lazy(() => import('./pages/register'));
const JoinedComponent = lazy(() => import('./pages/joined'));
const AppComponent = lazy(() => import('./pages/app'));

export default () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/account-confirmed" component={AccountConfirmedComponent} />
        <Route exact path="/forgot" component={ForgotComponent} />
        <Route exact path="/recover" component={RecoverComponent} />
        <Route exact path="/login" component={LoginComponent} />
        <Route path="/register" component={RegisterComponent} />
        <Route path="/joined/:secureToken" component={JoinedComponent} />

        <PrivateRoute path="/" component={AppComponent} />
      </Switch>
    </Suspense>
  );
};
